<template>
  <!-- m端 -->
  <div v-if="showState" class="hide-m">
    <div class="dow-fixed">			
			<div class="app-dow-box">
				<img @click="appDowShow" class="close-fixed-btn" src="@/assets/images/register/close-fixed.png" >
				<img class="app-icon" src="@/assets/images/register/app-icon.png" >
				<div class="app-text">
					<p class="p1">CM Trade</p>
					<p class="p2">{{$t('header.headerDow')}}</p>
				</div>
			</div>
			<a class="dow-btn" :href="links1">{{$t('header.headerDowBtn')}}</a>
		</div>
  </div>
  
</template>

<script>
import { setCookie,delCookie,getCookie } from '@/util/cookie';
export default{
  data() {
    return {
      showState:true,
      links1:'',
    }
  },
  created() {
    //app下载sem配置参数来源
    const utm_source = this.$route.query.utm_source;
    const utm_campaign = this.$route.query.utm_campaign;
    const utm_adgroup = this.$route.query.utm_adgroup;
    const utm_adgroupid = this.$route.query.utm_adgroupid;
    const utm_campaignid = this.$route.query.utm_campaignid;
    const utm_creative = this.$route.query.utm_creative;
    const utm_content =this.$route.query.utm_content;
    const utm_term = this.$route.query.utm_term;
    var APP_ID;
    if(utm_source){
				if(/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)){
					APP_ID = "id1615878597";
				}
				if(/android/i.test(navigator.userAgent)){
					APP_ID = "com.cmtrade.app.droid";
				}			
		  var oneLinkURL = "https://app.appsflyer.com/"+APP_ID+"?pid="+utm_source+"&c="+utm_campaign+"&af_adset="+utm_adgroup+"&af_adset_id="+utm_adgroupid+"&af_c_id="+utm_campaignid+"&af_ad="+utm_creative+"&af_ad_type="+utm_content+"&af_keywords="+utm_term;
      this.links1 = oneLinkURL;
    }else{
      this.links1 = "https://cmtrade.onelink.me/PzuJ/4t57jx7s";
    }
  },
  methods: {
    appDowShow(){
      this.showState = false
    }   
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dow-fixed{width: 84%;height: 2.5rem;padding: 0.2rem 1rem; background: #fff9e0; position: fixed;bottom: 0.6rem;left: 3%;border-radius: 5px;z-index: 99999; display: flex;flex-direction: row;justify-content: space-between;align-items: center;}
.dow-fixed .app-dow-box{position: relative;padding-left: 1rem; display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
.dow-fixed .close-fixed-btn{width: 0.8rem;height: 0.8rem;position: absolute;top: 0;left: -0.5rem;cursor: pointer;}
.dow-fixed .app-dow-box .app-icon{width: 1.8rem;height: 1.8rem;margin-right: 0.5rem;}
.dow-fixed .app-dow-box .app-text .p1{font-size: 0.7rem;color: #000;font-weight: 600;}
.dow-fixed .app-dow-box .app-text .p2{font-size: 0.6rem;color: #333;}
.dow-fixed .dow-btn{padding: 0.2rem 0.6rem;background: #ffd94c;color: #4c4c4c;cursor: pointer;}

@media screen and (max-width: 768px) {
.dow-fixed{width: 84%;height: 2.5rem;padding: 0.2rem 1rem; background: #fff9e0; position: fixed;bottom: 0.6rem;left: 3%;border-radius: 5px;z-index: 99999; display: flex;flex-direction: row;justify-content: space-between;align-items: center;}
.dow-fixed .app-dow-box{position: relative;padding-left: 1rem; display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
.dow-fixed .close-fixed-btn{width: 0.8rem;height: 0.8rem;position: absolute;top: 0;left: -0.5rem;cursor: pointer;}
.dow-fixed .app-dow-box .app-icon{width: 1.8rem;height: 1.8rem;margin-right: 0.5rem;}
.dow-fixed .app-dow-box .app-text .p1{font-size: 0.7rem;color: #000;font-weight: 600;}
.dow-fixed .app-dow-box .app-text .p2{font-size: 0.6rem;color: #333;}
.dow-fixed .dow-btn{padding: 0.2rem 0.6rem;background: #ffd94c;color: #4c4c4c;cursor: pointer;}
}
@media screen and (max-width: 414px) {}
@media screen and (max-width: 375px) {}
@media screen and (max-width: 360px) {}
@media screen and (max-width: 320px) {}
</style>
